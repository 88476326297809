import React, { useState, useEffect } from "react";
import axios from "axios";
import { serverUrl } from "./../../utils/config";
import { Dropdown } from "../dropdown/Dropdown";
import {Spinner} from "./../spinner/Spinner";
import { Modal } from "./../modal/Modal";

export const Products = (props) => {

  const { isLoading, setIsLoading } = props;

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filterCategory, setFilterCategory] = useState("");
  const [headerTitle, setHeaderTitle] = useState("Seleziona");
  const [viewModal, setViewModal] = useState(false);
  const [messageModal, setMessageModal] = useState("");
  const filteredProducts = filterCategory === "" ? products : products.filter(item => item.categoria === filterCategory.toLowerCase());
  const [confirmation, setConfirmation] = useState(false);
  const [itemSelected, setItemSelected] = useState(null);

  useEffect(() => {

    axios.post(`${serverUrl()}/cms/products.php`)
    .then((response) => {
      if (response.data.status === "success") {
        setProducts(response.data.data);
        console.log("prodotti: ", response.data.data);
      } else {
        console.log(response.data.message);
      }
    })
    .catch((error) => {
      console.error(error);
    });

    axios.post(`${serverUrl()}/cms/categories.php`)
    .then((response) => {
      if (response.data.status === "success") {
        console.log("categorie: ", response.data.data);
        setCategories(response.data.data);
      } else {
        console.log(response.data.message);
      }
    })
    .catch((error) => {
      console.error(error);
    });

  }, []);

  const TruncateText = (props) => {
    if (props.type === "words") {
      props.text = props.text.trim();
      let words = props.text.split(" ");
      return words.length > props.textLength ? words.slice(0, props.textLength).join(" ") + "..." : props.text;
    } else if (props.type === "chars") {
      return (props.text.length > props.textLength) ? props.text.slice(0, props.textLength) + "..." : props.text;
    }
  };

  const deleteProduct = (item) => {
    axios.post(`${serverUrl()}/cms/delete_product.php`, {
      id: item.id,
      categoria: item.categoria
    })
    .then((response) => {
      if (response.data.status === "success") {
        console.log("categorie: ", response.data.data);
        setProducts(response.data.data);
        setMessageModal(`Prodotto eliminato correttamente`);
      } else {
        console.log(response.data.message);
      }
    })
    .catch((error) => {
      console.error(error);
    });
  };

  return (
    <>
      <div className="cms-products">
        <div className="cms-filtri">
          <div className="cms-labelFiltri">Filtra per categoria </div>
          <div className="cms-filtersButtons">
            <Dropdown
              options={categories}
              setFilterCategory={setFilterCategory}
              headerTitle={headerTitle}
              setHeaderTitle={setHeaderTitle}
            />
            <div className="cms-contRemoveFilters">
              {filterCategory !== "" &&
                <button
                  type="button"
                  className="cms-removeFilter"
                  onClick={() => {
                    setFilterCategory("");
                    setHeaderTitle("Seleziona");
                    document.getElementById('dropdown').focus();
                  }}
                >
                  <i className="icon-cancel-2"></i>
                  Rimuovi filtro
                </button>
              }
            </div>
          </div>
        </div>
        {filteredProducts.length > 0 ? (
          <div className="cms-countResults">Prodotti trovati: {filteredProducts.length}</div>
        ) : (
          <div className="cms-noResults">Non ci sono prodotti</div>
        )
        }
        <div className="cms-list-items">
          {filteredProducts.map((item, i) => {
            return (
              <div key={i} className="cms-item">
                <div className="cms-img">
                  <img src={require(`./../../../../assets/img/${item.img}`)} alt="" />
                </div>
                <div className="cms-info">
                  <div className="cms-title">{item.titolo}</div>
                  <div className="cms-description">
                    {TruncateText({
                      type: "chars", // chars or words
                      text: item.descrizione,
                      textLength: 200,
                    })}
                  </div>
                  <div className="cms-info">
                    <div className="cms-categoria">
                      Categoria: {item.categoria.replace("-", " ")}
                    </div>
                  </div>
                  <div className="cms-actions">
                    <button
                      type="button"
                      onClick={() => {}}
                    >
                      Dettagli
                    </button>
                    <button
                      type="button"
                      onClick={(e) => {
                        // deleteProduct(item);
                        setConfirmation(true);
                        setMessageModal(`Sicuro di volere eliminare questo prodotto? \n ${item.titolo}`);
                        setViewModal(true);
                        setItemSelected(item);
                        window.lastElementClicked = e.target;
                      }}
                    >
                      Elimina
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Spinner isLoading={isLoading} setIsLoading={setIsLoading} />
      {viewModal &&
        <Modal 
          item={itemSelected}
          title={`Conferma`}
          message={messageModal} 
          setViewModal={setViewModal}
          confirmation={confirmation}
          setConfirmation={setConfirmation}
          callback={deleteProduct}
        />
      }
    </>
  );
}