import React from "react";

import {Sidebar} from "./../sidebar/Sidebar";
import { Categories } from "./../categories/Categories";
import { Products } from "./../products/Products";
import { AddProduct } from "./../addProduct/AddProduct";


export const Body = (props) => {

  const {page, setPage, isLoading, setIsLoading} = props;

  return (
    <div className="container-body">
      <Sidebar page={page} setPage={setPage} setIsLoading={setIsLoading} />
      {page === "categories" &&
        <Categories isLoading={isLoading} setIsLoading={setIsLoading} />
      }
      {page === "products" &&
        <Products isLoading={isLoading} setIsLoading={setIsLoading} />
      }
      {page === "add-product" &&
        <AddProduct isLoading={isLoading} setIsLoading={setIsLoading} />
      }
    </div>
  );
};