import React, { useEffect } from 'react';
import { Helmet, HelmetData } from 'react-helmet-async';

export const Seo = ({ title, description, imageUrl }) => {
  const helmetData = new HelmetData({});
  useEffect(() => {
    // Use Helmet here if you need to dynamically update meta tags after the initial load
  }, [title, description, imageUrl]);

  return (
    <Helmet helmetData={helmetData} >
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property='og:type' content='article' />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={imageUrl} />
      <meta property="og:site_name" content="Adviso" />
      <meta property="og:section" content="Home" />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:site_name" content="Adviso" />
      <link rel="canonical" href="https://www.adviso.it" />
    </Helmet>
  );
};