import React, { useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";

export const Menu = ({ 
  openSubMenu, 
  windowWidth, 
  resetOpenMenu, 
  setCategory, 
  categories, 
  setIsLoading,
  products,
  setSearch,
  setProductsFilteredCategory,
  setProductsFilteredSearch
}) => {

  const inputSearch = useRef("");
  const ref = useRef(null);
  const navigate = useNavigate();

  const searchProducts = () => {
    if(inputSearch.current !== ""){
      resetOpenMenu();
      setIsLoading(true);
      setTimeout(() => {
        document.getElementById('searchInputMobile').value = "";
        setSearch(inputSearch.current);
        let productsFiltered = products.filter((item) => item.titolo.toLowerCase().indexOf(inputSearch.current.toLowerCase()) > -1 || item?.descrizione.toLowerCase().indexOf(inputSearch.current.toLowerCase()) > -1);
        setProductsFilteredSearch(productsFiltered);
        navigate(`/cerca`);
      }, 300);
    }
  };

  return (
    <div className={`menuCategory ${openSubMenu ? "open" : ""}`}>
      <div className="container">
        <div className="search">
          <input 
            type="text" 
            ref={ref}
            autoComplete="off"
            id="searchInputMobile"
            placeholder="Cerca prodotti"
            onChange={(e) => {
              inputSearch.current = e.target.value;
            }}
            onKeyDown={(e) => {
              if(e.key === "Enter"){
                searchProducts();
              }
            }}
          />
          <button
            type="button"
            onClick={() => {
              resetOpenMenu();
              searchProducts();
            }}
          >
            <span className="material-symbols-outlined search-icon">search</span>
          </button>
        </div>
        <ul>
          {windowWidth <= 768 && 
            <li className="submenuItem">
              <NavLink 
                onClick={() => {
                  setIsLoading(true);
                  resetOpenMenu();
                  setCategory("");
                }} 
                className={({ isActive }) => isActive ? 'active' : ''}  
                to={'/' || '/home'}
              >
                Home
              </NavLink>
            </li>
          }
          {categories.map((item, i) => {
            // let categoryTag = item?.categoria.replace(/ /g, "-").replace("à", "a").toLowerCase();
            let categoryTag = item?.categoria.replace("à", "a").toLowerCase();
            return (
              <li className="submenuItem" key={i}>
                <NavLink 
                  onClick={() => {
                    setIsLoading(true);
                    setTimeout(() => {
                      resetOpenMenu();
                      setCategory(categoryTag);
                      document.querySelector('html').scrollTo(0, 0);
                      setProductsFilteredCategory(
                        products.filter((item) => {
                          return item.categoria.toLowerCase().indexOf(categoryTag.toLowerCase()) > -1;
                        })
                      );
                    }, 500);
                  }} 
                  className={({ isActive }) => isActive ? 'active' : ''} 
                  to={`/categoria/${categoryTag}`} 
                >
                  {item?.categoria}
                </NavLink>
              </li>
            );
          })}
          {/* {windowWidth <= 768 && 
            <li className="submenuItem">
              <NavLink 
                onClick={() => {
                  setIsLoading(true);
                  resetOpenMenu();
                  setCategory("");
                }} 
                className={({ isActive }) => isActive ? 'active' : ''} 
                to={'/contatti'} 
              >
                Contatti
              </NavLink>
            </li>
          } */}
        </ul>
      </div>
      {/* {windowWidth <= 768 &&
        <div className="actionUser">
          <button
            type="button"
            className="registrati"
            onClick={() => {
              resetOpenMenu();
            }}
          >
            Registrati
          </button>
          <button
            type="button"
            className="accedi"
            onClick={() => {
              resetOpenMenu();
            }}
          >
            Accedi
          </button>
        </div>
      } */}
    </div>
  );
};