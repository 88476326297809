import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { serverUrl } from "./../../utils/config";
import {Spinner} from "./../spinner/Spinner";

export const Categories = (props) => {

  const {isLoading, setIsLoading} = props;

  const [categories, setCategories] = useState([]);
  const [categoryAdded, setCategoryAdded] = useState("");
  const inputRef = useRef(null);

  const handleOnChange = (id, elem) => {
    axios.post(`${serverUrl()}/cms/update_categories.php`, {
      id: id,
      enabled: elem.checked
    })
      .then((response) => {
        if (response.data.status === "success") {
          console.log("categorie: ", response.data.data);
          setCategories(response.data.data);
        } else {
          console.log(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const addCategory = () => {
    if(categoryAdded !== ""){
      axios.post(`${serverUrl()}/cms/add_categories.php`, {
        categoria: categoryAdded
      })
      .then((response) => {
        if (response.data.status === "success") {
          console.log("categorie: ", response.data.data);
          setCategories(response.data.data);
          inputRef.current.value = "";
        } else {
          console.log(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    }
  };

  const deleteCategory = (id, categoria) => {
    if (window.confirm(`Vuoi eliminare la categoria: ${categoria}?`)) {
      axios.post(`${serverUrl()}/cms/delete_categories.php`, {
        id: id
      })
      .then((response) => {
        if (response.data.status === "success") {
          console.log("categorie: ", response.data.data);
          setCategories(response.data.data);
          setCategoryAdded("");
        } else {
          console.log(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    }
  };

  useEffect(() => {
    axios.post(`${serverUrl()}/cms/categories.php`)
    .then((response) => {
      if (response.data.status === "success") {
        console.log("categorie: ", response.data.data);
        setCategories(response.data.data);
      } else {
        console.log(response.data.message);
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }, []);

  return (
    <>
      <div className="cms-categories">
        <div className="cms-addCategories">
          <div className="cms-labelCategories">Aggiungi categoria</div>
          <div className="cms-fieldContainer">
            <div>
              <input
                ref={inputRef}
                type="text"
                placeholder="Categoria"
                onChange={(e) => {
                  setCategoryAdded(e?.target?.value);
                }}
              />
            </div>
            <div>
              <button
                type="button"
                onClick={() => {
                  addCategory();
                }}
              >
                Aggiungi
              </button>
            </div>
          </div>
        </div>
        <div className="cms-table">
          <div className="cms-row">
            <div className="cms-cell cms-heading">Categoria</div>
            <div className="cms-cell cms-heading">Status</div>
            <div className="cms-cell cms-heading"></div>
          </div>
          {categories.map((item, i) => {
            return (
              <div className="cms-row" key={i}>
                <div className="cms-cell">{item.categoria}</div>
                <div className="cms-cell">
                  <label htmlFor={`status-${i}`}>
                    <input
                      id={`status-${i}`}
                      type="checkbox"
                      checked={item.enabled === "1" ? true : false}
                      onChange={(e) => handleOnChange(item.id, e.target)}
                    />
                    {item.enabled === "1" ? "Disabilita" : "Abilita"}
                  </label>
                </div>
                <div className="cms-cell">
                  <button
                    type="button"
                    onClick={() => {
                      deleteCategory(item.id, item.categoria);
                    }}
                  >
                    Elimina
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Spinner isLoading={isLoading} setIsLoading={setIsLoading} />
    </>
  );
}