import React from "react";
import { Spinner } from "./../../spinner/Spinner";
import { Seo } from "../../seo/Seo";

export const TerminiCondizioni = ({isLoading, setIsLoading}) => {
  
  return (
    <>
      <Seo 
        title={`Adviso | Termini e condizioni`}
        description={`Prodotti consigliati da acquistare su Amazon in questo momento`}
        imageUrl={`${require('./../../../assets/logo-small.png')}`}
      />
      <Spinner isLoading={isLoading} setIsLoading={setIsLoading} />
      <div className="termini">
        <div className="container">
          <div className="container-html">
            <h2>
              Termini e condizioni
            </h2>
            <h3>Condizioni di accesso</h3>
            <p>
              La navigazione e l'utilizzo del sito web raggiungibile alla URL <a href="http://https://www.adviso.it">https://www.adviso.it</a> (di seguito “sito”) comportano
              l'accettazione integrale delle presenti condizioni di utilizzo e di tutte le condizioni
              pubblicate alla presente pagina e nelle apposite pagine e sezioni del sito per
              la fruizione dei servizi o di altri materiali e/od opzioni disponibili (di
              seguito, complessivamente, “servizi”). </p>
            <p>Ai fini delle
              presenti note legali si intende per “utente” il soggetto che fruisce dei
              servizi offerti dal sito <a href="http://https://www.adviso.it">https://www.adviso.it</a> sia in caso di consultazione
              delle informazioni ivi inserite sia in caso di accesso alle aree riservate.</p>
            <p>L'utente che
              accede o utilizza il sito dichiara di aver visionato e di accettare le condizioni
              delle presenti Note legali e/o qualsiasi altra nota, avviso legale, informativa
              o disclaimer ovunque pubblicati nel sito. </p>
            <p>
              Adviso si riserva il diritto di aggiornare o variare le
              presenti condizioni e le modalità di utilizzo del sito web, e/o di predisporre ulteriori e specifiche condizioni a disciplina
              dei singoli servizi offerti nel sito senza alcun obbligo di preavviso. Gli
              utenti sono invitati pertanto a consultare la presente sezione regolarmente.</p>
            <h3>Informazioni generali - Informazioni rese ex Legge 62/2001</h3>
            <p>Lo scopo del sito è quello di fornire informazioni su tutte le attività
              di Adviso. </p>
            <p>Consente
              inoltre all'utente di registrarsi per usufruire di alcune funzionalità e di
              iscriversi online per la sottoscrizione della quota associativa di medesima, mediante la
              sottoscrizione di una quota di un
              importo di 2 (due) euro. Questa attività è di natura sociale e non a scopro di
              lucro.</p>
            <p>Il sito raggiungibile alla URL <a href="http://https://www.adviso.it">https://www.adviso.it</a> è pubblicato da BestAdvice, che ne cura la gestione e i contenuti. </p>
            <p> Adviso è responsabile della pubblicazione del
              sito e può essere contattata all'indirizzo di posta elettronica <a href="mailto:bestadvice@altervista.org">bestadvice@altervista.org</a> o agli indirizzi di posta ordinaria e recapiti indicati nel sito.</p>
            <p>L'hosting del sito risiede in Italia.</p>

            <p>Il sito utilizza la tecnologia JavaScript. </p>
            <p>I contenuti del
              sito non hanno carattere di periodicità e non rappresentano un “prodotto
              editoriale”. Il sito non contiene informazioni aggiornate con cadenza
              periodica regolare. L'aggiornamento avviene secondo disponibilità e necessità,
              non quantificabili temporalmente e non scadenzabili. I controlli sui contenuti
              riguardano solo ed esclusivamente quelli pubblicati direttamente dal sito. I
              link ad altre pagine non rientrano nei controlli in questione. Il sito è normalmente accessibile agli utenti
              in qualsiasi momento. Tuttavia, un'interruzione a causa di manutenzione tecnica
              può essere decisa dal Adviso, che cercherà di
              comunicare le modalità dell'intervento agli utenti.</p>
            <h3>Garanzie, limitazioni e Responsabilità</h3>
            <p>Nei limiti di quanto consentito dalla vigente normativa applicabile gli
              utenti accettano il sito e tutti i suoi contenuti, ivi compresi i servizi
              eventualmente offerti. Riconoscono che l'utilizzo del sito avviene a proprio
              esclusivo rischio.</p>
            <p>Adviso
              non si assume alcuna responsabilità per gli eventuali problemi derivanti
              dall'utilizzazione del presente sito o di eventuali siti esterni ad esso
              collegati (ivi compresi i risultati che sono attesi, sperati od ottenuti tramite l'utilizzo del medesimo, eventuali incompatibilità
              con le apparecchiature dell'utente, eventuali virus, bachi …).</p>
            <p>
              Adviso si impegna a fornire informazioni e a rendere disponibili i
              contenuti sul sito in modo puntuale e il più esaustivo e corretto possibile. Tuttavia
              non è responsabile per eventuali omissioni, inesattezze o carenze
              nell'aggiornamento. Le informazioni contenute nel sito possono essere
              indicative e non esaustive e possono evolvere nel tempo, in quanto passibili di
              modifiche dal momento in cui sono state pubblicate sul sito medesimo. Adviso non è responsabile inoltre dei contenuti presenti/pubblicati in/da
              siti/applicazioni terze accessibili tramite link dal sito <a href="http://https://www.adviso.it">https://www.adviso.it</a> </p>
            <p>In nessuna
              circostanza, Adviso potrà essere ritenuta responsabile per qualsiasi danno diretto, indiretto,
              incidentale, consequenziale, legato all'uso del presente sito web o di altri
              siti web ad esso collegati da un link ipertesto, ivi compresi senza alcuna
              limitazione, i danni quali la perdita di profitti o fatturato, perdita di
              immagine o di mercato, l'interruzione di attività aziendale o professionale, la
              perdita di programmi o altro tipo di dati ubicati sul sistema informatico
              dell'utente o altro sistema, i danni causati al materiale dell'utente, i danni
              derivanti dall'uso di materiale che non soddisfa le specifiche di cui al punto
              dedicato alla “Legge applicabile e attribuzione della giurisdizione”, i danni
              derivanti da bug o incompatibilità </p>
            <p>
              Adviso, salvi i propri obblighi contrattuali eventualmente
              assunti, declina ogni responsabilità per le eventuali pretese degli utenti
              relative all'impossibilità di utilizzare il sito e/o i servizi per qualsiasi
              ragione. </p>
            <p>
              Adviso, salvi i propri obblighi contrattuali eventualmente
              assunti, si riserva in ogni tempo il diritto di disconnettere, temporaneamente
              o permanentemente i propri servizi (o una qualunque loro parte).</p>
            <p>L'utente
              riconosce che Adviso, salvi i propri obblighi
              contrattuali eventualmente assunti, non potrà in alcun modo essere ritenuta
              responsabile verso l'utenza o verso terzi per la sospensione o l'interruzione dei
              propri servizi/presente contratto.</p>
            <p>Nei limiti
              previsti dall'articolo 1229 del Codice Civile, gli utenti dichiarano e
              garantiscono di tenere indenne e manlevare Adviso,
              i suoi rappresentanti, dipendenti, collaboratori, consorziati nonché i suoi
              partners da qualsiasi obbligo risarcitorio, incluse le spese legali, che
              possano originarsi a loro carico in relazione all'utilizzo del sito. Se la
              legislazione applicabile non consente un esonero da responsabilità, la
              responsabilità dovrà in ogni caso esser limitata nei limiti in cui la legge lo
              consenta. </p>
            <p>La presente
              clausola di esclusione della responsabilità non ha lo scopo di eludere il
              rispetto dei requisiti prescritti dalla normativa vigente, nè di escludere la
              responsabilità per i casi nei quali essa non possa essere esclusa ai sensi
              della normativa applicabile.</p>
            <h3>Utilizzo delle informazioni - Obblighi e responsabilità dell'utente</h3>
            <p>L'Utente si
              impegna a non utilizzare i servizi o funzionalità del sito per:</p>
            <ol>
              <li>caricare, pubblicare, inviare
                privatamente o in altro modo trasmettere o diffondere contenuti che siano illeciti, dannosi,
                minatori, abusivi, molesti, diffamatori e/o calunniosi, volgari, osceni, lesivi
                della privacy altrui, razzisti, classisti o comunque reprensibili;</li>
              <li>arrecare danno, in
                qualsivoglia modo, a minori di età;</li>
              <li>falsificare la propria
                identità;</li>
              <li>manipolare, contraffare un contenuto trasmesso o diffuso
                tramite i servizi, o qualsiasi altro tipo di funzionalità disponibile sul sito;</li>
              <li>caricare, pubblicare,
                inviare privatamente o in altro modo trasmettere o diffondere un contenuto che
                non abbia il diritto di trasmettere o diffondere in forza di una previsione di
                legge, di contratto ovvero a causa di un rapporto fiduciario (per esempio
                informazioni riservate, informazioni confidenziali apprese in forza di una
                rapporto di lavoro o protette da un patto di riservatezza);</li>
              <li>caricare, pubblicare, inviare privatamente o in altro
                modo trasmettere o diffondere un contenuto che comporti la violazione di
                brevetti, marchi, segreti, diritti di autore o altri diritti di proprietà
                industriale e/o intellettuale di terzi soggetti (“Diritti”);</li>
              <li>caricare, pubblicare,
                inviare privatamente o in altro modo trasmettere o diffondere pubblicità,
                materiale promozionale, “junk mail”, “spam”, piramidi, o qualsiasi altra forma di
                sollecitazione non autorizzate o non richieste;</li>
              <li>caricare, affiggere,
                inviare privatamente o in altro modo trasmettere o diffondere qualsivoglia
                materiale che contenga virus o altri codici, file o programmi creati per
                interrompere, distruggere o limitare il funzionamento dei software, degli
                hardware o degli impianti di telecomunicazioni di terzi;</li>
              <li>interferire o interrompere i servizi e/o i server
                collegati con i servizi;</li>
              <li>violare, intenzionalmente o no, qualsivoglia legge o
                regolamento applicabile;</li>
              <li>perseguitare o in
                altro modo molestare terzi soggetti; raccogliere o immagazzinare dati personali
                degli altri utenti.</li>
            </ol>
            <p>In ogni caso,
              Adviso si riserva il diritto di rimuovere,
              senza preavviso, qualsiasi contenuto depositato in questo spazio che potrebbe
              violare la legislazione applicabile in Italia, in particolare le disposizioni
              relative alla protezione dei dati. Ove applicabile, Adviso si riserva inoltre il diritto di agire nei confronti dell'utente,
              secondo le leggi italiane e la normativa vigente, in particolare nel caso di
              messaggi razzisti, abusivi, diffamatori o pornografici, a prescindere dalla
              supporto utilizzato (testo, fotografia…).</p>
            <p>Resta
              espressamente inteso che Adviso su richiesta delle autorità
              competenti ed a fronte della comprovata illiceità e/o della offensività di
              taluni contenuti autonomamente inseriti nel sito da terzi mediante i menzionati
              servizi, o qualsiasi altra funzionalità disponibile sul sito, agirà
              immediatamente per rimuovere tempestivamente tali contenuti, sempre
              compatibilmente con i tempi tecnici richiesti per tale operazione. </p>
            <p>Tutti i costi
              sostenuti dall'utente per accedere al servizio (hardware del computer,
              software, connessione Internet, ecc.) sono a suo carico. Inoltre, l'utente del
              sito si impegna ad accedere al sito utilizzando un hardware recente, non
              contenente virus e con un browser aggiornato di ultima generazione.</p>
            <p>Votando i
              questionari relativi ai prodotti di Adviso,
              messi a disposizione sul sito, l'utente si impegna a compiere le sue scelte in
              conformità con i suoi desideri e le sue aspettative. Si impegna a non
              distorcere deliberatamente i risultati del questionario, al fine di rispettare
              i valori fondanti del progetto di.</p>
            <p>In caso di
              utente socio di, l'utente si impegna a fornire informazioni
              accurate, complete e di attualità.</p>
            <p>Nel caso in
              cui le informazioni fornite siano inesatte, incomplete o obsolete,
              Adviso, adotta ogni azione opportuna per sospendere
              l'adesione dell'associato
              al
              Adviso in attesa della regolarizzazione delle informazioni trasmesse.</p>
            <p>L'utente si
              impegna a fornire le informazioni obbligatorie richieste dal in
              base al servizio utilizzato, per consentire il trattamento della sua
              applicazione in condizioni normali. L'utente è l'unico responsabile per
              l'accuratezza delle informazioni fornite al, che deve essere
              accurata e completa. L'utente garantisce inoltre la veridicità e l'accuratezza
              delle informazioni fornite da se stesso o da qualsiasi altra terza parte utilizzando
              i suoi dati su questo sito.</p>
            <h3>Proprietà intellettuale e industriale</h3>
            <p>I contenuti
              del sito - codice di script, grafica, testi, tabelle, immagini, suoni, e ogni
              altra informazione disponibile in qualunque forma - sono protetti ai sensi
              della normativa in tema di opere dell'ingegno. </p>
            <p>Ogni prodotto
              o società menzionati in questo sito sono marchi dei rispettivi proprietari o
              titolari e possono essere protetti da brevetti e/o copyright concessi o
              registrati dalle autorità preposte.Tutti i loghi e marchi presenti sul
              sito sono di proprietà dei rispettivi licenziatari. </p>
            <p>
              Adviso possiede i
              diritti di proprietà intellettuale o possiede i diritti di utilizzo su tutti
              gli elementi accessibili sul sito, inclusi testi, documenti, immagini, grafica,
              logo, icone, suoni, software, salvo quanto diversamente espresso; sono
              considerate “informazioni per il pubblico” e non possono essere
              distribuite e/o copiate senza permesso ed espressa autorizzazione scritta. </p>
            <p>Eventuali
              contenuti di proprietà terza recheranno l'indicazione della rispettiva
              provenienza o sarà presente idonea citazione per garantire l'attribuzione dei
              diritti ai legittimi proprietari o autori.</p>
            <p>Qualsiasi
              riproduzione, rappresentazione, modifica, pubblicazione, adattamento di tutti o
              parte degli elementi del sito, qualunque sia il mezzo o il processo utilizzato,
              è vietata, salvo previa autorizzazione scritta da parte di Adviso. </p>
            <p>Qualsiasi
              sfruttamento non autorizzato del sito o di uno qualsiasi degli elementi in esso
              contenuti sarà considerato come costitutivo di una violazione e perseguita in
              conformità con le disposizioni degli articoli L. 335-2 e segg. del codice della
              proprietà intellettuale.</p>
            <h3>Collegamenti ad altri siti con dominio diverso</h3>
            <p>Il sito
              potrebbe offrire collegamenti (d'ora in avanti “link”) ad altri siti web o ad
              altre risorse della rete Internet. Adviso non
              può essere in alcun modo ritenuta responsabile per il funzionamento di tali
              siti o risorse esterne. Pertanto l'utente si assume ogni responsabilità per
              l'utilizzo e per gli acquisti eventualmente effettuati dei servizi o prodotti
              offerti dai suddetti siti.</p>
            <h3>Privacy e Cookie</h3>
            <p>Il trattamento dei dati personali dell'utente acquisiti nell'ambito della navigazione
              e/od utilizzazione dei servizi o di qualsiasi altra funzionalità del sito avviene
              nel pieno rispetto degli obblighi previsti dal Regolamento europeo n. 679/2016
              (GDPR) , dal Decreto Legislativo 30 giugno 2003, n. 196 “Codice della Privacy”)
              e s.s.m.i con le modalità e finalità specificate nell'Informativa Privacy o
              nelle singole Informative eventualmente pubblicate in ogni sezione del sito ove si procede alla raccolta di dati personali degli
              utenti.</p>
            <p>Il sito o siti / strumenti / applicazioni di terzi da questo utilizzati si avvalgono di cookie necessari al funzionamento ed utili alle finalità illustrate nella policy dei cookie. I siti terzi potrebbero installare cookie di profilazione. L'utente può consultare la Policy sui cookie adottata dal Adviso e pubblicata all'interno dell'<a href="https://www.adviso.it/privacy-policy">Informativa Privacy</a> presente nel sito, ove sono definite le modalità di trattamento e le modalità per negare il consenso a tutti o ad alcuni cookie.</p>
            <h3>Legge applicabile e giurisdizione</h3>
            <p>Qualsiasi
              controversia in relazione all'utilizzo del sito <a href="https://www.adviso.it/">https://www.adviso.it/</a>
              è soggetta alla legge Italiana. La giurisdizione esclusiva è concessa al
              Tribunale competente di Roma.</p>
            <h3>Le principali leggi in questione</h3>
            <p>Legge n.
              78-87 del 6 gennaio 1978, in particolare modificata dalla legge 6 agosto 2004,
              n. 2004-801, concernente la tecnologia dell'informazione, i fascicoli e le
              libertà.</p>
            <p>Legge del 1 °
              luglio 1998 che recepisce la direttiva 96/9 dell'11 marzo 1996 sulla protezione
              giuridica delle banche dati.</p>
            <p>Legge n.
              2004-575 del 21 giugno 2004 per la fiducia nell'economia digitale.</p>
            <p>Legge n.
              2018-493 del 20 giugno 2018 sulla protezione dei dati personali.</p>
          </div>
        </div>
      </div>
    </>
  );
};  