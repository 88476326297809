import React, { useState, useEffect } from 'react';
import axios from "axios";
import { serverUrl } from "./../../utils/config";
import { Header } from './../header/Header';
import { Footer } from './../footer/Footer';
import { Home } from "./../pages/home/Home";
import { Contatti } from "./../pages/contatti/Contatti";
import { Categorie } from "./../pages/categorie/Categorie";
import { Search } from "./../pages/search/Search";
import { TerminiCondizioni } from "./../pages/policy/TerminiCondizioni";
import { Privacy } from "./../pages/policy/Privacy";

export const Pages = (props) => {

  const {
    page,
  } = props;

  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [category, setCategory] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [openMenuMobile, setOpenMenuMobile] = useState(false);
  const [products, setProducts] = useState([]);
  const [productsFilteredCategory, setProductsFilteredCategory] = useState([]);
  const [productsFilteredSearch, setProductsFilteredSearch] = useState([]);
  const [categories, setCategories] = useState([]);

  const resetOpenMenu = () => {
    setOpenSubMenu(false);
    setOpenMenuMobile(false);
    document.querySelector('body').classList.remove('open-menu');
  };

  useEffect(() => {
    new ResizeObserver(() => {
      setWindowWidth(window.innerWidth);
    }).observe(document.body);
  }, []);

  
  useEffect(() => {
    axios.post(`${serverUrl()}/categories.php`)
      .then((response) => {
        if (response.data.status === "success") {
          setCategories(response.data.data);
        } else {
          console.log(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axios.post(`${serverUrl()}/products.php`)
    .then((response) => {
      if (response.data.status === "success") {
        setProducts(response.data.data);
      } else {
        console.log(response.data.message);
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }, []);

  return (
    <>
      <Header
        openSubMenu={openSubMenu}
        setOpenSubMenu={setOpenSubMenu}
        openMenuMobile={openMenuMobile}
        setOpenMenuMobile={setOpenMenuMobile}
        resetOpenMenu={resetOpenMenu}
        windowWidth={windowWidth}
        setCategory={setCategory}
        categories={categories}
        setIsLoading={setIsLoading}
        setSearch={setSearch}
        setProducts={setProducts}
        products={products}
        setProductsFilteredSearch={setProductsFilteredSearch}
        setProductsFilteredCategory={setProductsFilteredCategory}
      />
      {page === "home" && 
        <Home products={products} isLoading={isLoading} setIsLoading={setIsLoading} />
      }
      {page === "categorie" &&
        <Categorie
          products={products}
          category={category}
          categories={categories}
          setCategory={setCategory}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          productsFilteredCategory={productsFilteredCategory}
          setProductsFilteredCategory={setProductsFilteredCategory}
        />
      }
      {page === "contatti" && 
        <Contatti isLoading={isLoading} setIsLoading={setIsLoading} />
      }
      {page === "cerca" && 
        <Search
          productsFilteredSearch={productsFilteredSearch}
          search={search}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      }
      {page === "termini-condizioni" &&
        <TerminiCondizioni
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      }
      {page === "privacy-policy" &&
        <Privacy
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      }
      <Footer
        resetOpenMenu={resetOpenMenu}
        setCategory={setCategory}
        categories={categories}
        setIsLoading={setIsLoading}
      />
    </>
  );
};