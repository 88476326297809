import React from "react";
import {Spinner} from "./../../spinner/Spinner";
import { Seo } from "../../seo/Seo";

export const Contatti = ({isLoading, setIsLoading}) => {

  return (
    <>
      <Seo 
        title={`Adviso | Contatti`}
        description={`Prodotti consigliati da acquistare su Amazon in questo momento`}
        imageUrl={`${require('./../../../assets/logo-small.png')}`}
      />
      <Spinner isLoading={isLoading} setIsLoading={setIsLoading} />
      <div className="contatti">
        <div className="container">
          <div className="titleContact">
            <h1>Contatti</h1>
          </div>
        </div>
      </div>
    </>
  );
};