import React from "react";
import { Spinner } from "../../spinner/Spinner";
import { Products } from "../home/products/Products";
import { Seo } from "../../seo/Seo";

export const Search = ({productsFilteredSearch, search, isLoading, setIsLoading, products}) => {

  return (
    <>
      <Seo 
        title={`Adviso | Ricerca prodotti`}
        description={`Prodotti consigliati da acquistare su Amazon in questo momento`}
        imageUrl={`${require('./../../../assets/logo-small.png')}`}
      />
      <Spinner isLoading={isLoading} setIsLoading={setIsLoading} />
      <div className="searchComponent">
        <div className="titleSearch">
          <div className="container">
            {search !== "" ? 
              <h1>Risultati trovati per: <b>{search}</b> ({productsFilteredSearch.length})</h1>
              :
              <h1>Risultati trovati ({search !== "" ? productsFilteredSearch.length : 0})</h1>
            }
          </div>
        </div>
        <Products products={search !== "" ? productsFilteredSearch : []} />
      </div>
    </>
  );
};