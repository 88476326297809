import React, { useEffect, useState } from "react";
import {Spinner} from "./../spinner/Spinner";
import axios from "axios";
import { serverUrl } from "./../../utils/config";
import { Dropdown } from "../dropdown/Dropdown";
import { Modal } from "./../modal/Modal";

export const AddProduct = (props) => {

  const [categories, setCategories] = useState([]);
  const [headerTitle, setHeaderTitle] = useState("Seleziona");
  const {isLoading, setIsLoading} = props;
  const [viewImage, setViewImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [file, setFile] = useState(null);
  const [categoria, setCategoria] = useState("");
  const [titolo, setTitolo] = useState("");
  const [prezzo, setPrezzo] = useState("");
  const [link, setLink] = useState("");
  const [descrizione, setDescrizione] = useState("");
  const [viewModal, setViewModal] = useState(false);
  const [messageModal, setMessageModal] = useState("");

  useEffect(() => {
    axios.post(`${serverUrl()}/cms/categories.php`)
    .then((response) => {
      if (response.data.status === "success") {
        setCategories(response.data.data);
      } else {
        console.log(response.data.message);
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }, []);

  const addImage = (element) => {
    let file = element, src = (window.URL || window.webkitURL).createObjectURL(file);
    setFile(element);
    setViewImage(true);
    setSelectedImage(src);
    console.log(element);
  };

  const removeImage = () => {
    setViewImage(false);
    setSelectedImage("");
    document.getElementById('file').value = "";
    document.getElementById('file').focus();
  };

  const validateForm = () => {
    return (
      (selectedImage !== "" ? 1 : 0) +
      (categoria !== "" ? 1 : 0) +
      (titolo !== "" ? 1 : 0) +
      // (prezzo !== "" ? 1 : 0) +
      (link !== "" ? 1 : 0) +
      (descrizione !== "" ? 1 : 0)
    );
  };

  const resetFields = () => {
    removeImage();
    setTitolo("");
    setPrezzo("");
    setLink("");
    setDescrizione("");
    setCategoria("");
    setHeaderTitle("Seleziona");
    document.getElementById('titolo').value = "";
    document.getElementById('prezzo').value = "";
    document.getElementById('link').value = "";
    document.getElementById('descrizione').value = "";
  };

  const submit = () => {
    if(validateForm() >= 5){
      const data = new FormData();
      data.append('file', file);
      data.append('categoria', categoria);
      data.append('titolo', titolo);
      data.append('prezzo', prezzo);
      data.append('link', link);
      data.append('descrizione', descrizione);
      console.log(data);
      axios.post(`${serverUrl()}/cms/add_product.php`, data)
      .then((response) => {
        if (response.data.status === "success") {
          console.log("add product: ", response.data.data);
          resetFields();
          setMessageModal("Prodotto inserito correttamente");
          setViewModal(true);
        } else {
          console.log(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    }
  };

  return (
    <>
      <div className="cms-addProduct">
        <div className="cms-fieldContainer">
          <div className="cms-doubleRow">
            <div className="cms-imageContainer">
              {viewImage &&
                <button 
                  type="button"
                  className="cms-remove-image"
                  onClick={() => {
                    removeImage();
                  }}
                >
                  <i className="icon-cancel-circled"></i>
                </button>
              }
              <input 
                id="file"
                tabIndex={0}
                type="file" 
                name="files[]" 
                accept="image/*"
                onChange={(e) => {
                  addImage(e.target?.files?.[0]);
                }}
              />
              <span className="cms-label">
                Seleziona un immagine
                <i className="icon-picture"></i>
              </span>
              {viewImage &&
                <img className="cms-image-uploaded" src={selectedImage} alt="" />
              }
            </div>
            <div className="cms-fields">
              <div className="cms-field">
                <label htmlFor="categoria">Categoria</label>
                <Dropdown
                  id="categoria"
                  options={categories}
                  setFilterCategory={setCategoria}
                  headerTitle={headerTitle}
                  setHeaderTitle={setHeaderTitle}
                />
              </div>
              <div className="cms-field">
                <label htmlFor="titolo">Inserisci titolo</label>
                <input 
                  id="titolo"
                  type="text" 
                  autoComplete="off"
                  placeholder="Titolo prodotto"
                  onChange={(e) => {
                    setTitolo(e.target.value);
                  }}
                />
              </div>
              <div className="cms-field">
                <label htmlFor="prezzo">Inserisci prezzo</label>
                <input 
                  id="prezzo"
                  type="text"
                  autoComplete="off" 
                  placeholder="Prezzo prodotto"
                  onChange={(e) => {
                    setPrezzo(e.target.value);
                  }}
                />
              </div>
              <div className="cms-field">
                <label htmlFor="link">Inserisci link</label>
                <input 
                  id="link"
                  type="text" 
                  autoComplete="off"
                  placeholder="Link prodotto"
                  onChange={(e) => {
                    setLink(e.target.value);
                  }}
                />
              </div>
            </div>
            
          </div>
          <div className="cms-singleRow">
            <div className="cms-field">
              <label htmlFor="descrizione">Descrizione prodotto</label>
              <textarea 
                id="descrizione"
                autoComplete="off"
                placeholder="Descrizione prodotto"
                cols="30" 
                rows="10"
                onChange={(e) => {
                  setDescrizione(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="cms-footerButtons">
          <button
            type="button"
            onClick={() => {
              submit();
            }}
          >
            <i className="icon-floppy"></i>
            Salva
          </button>
          <button
            type="button"
            onClick={() => {
              resetFields();
            }}
          >
            <i className="icon-cancel-2"></i>
            Cancella
          </button>
        </div>
      </div>
      <Spinner isLoading={isLoading} setIsLoading={setIsLoading} />
      {viewModal &&
        <Modal 
          title={`Conferma`}
          message={messageModal} 
          setViewModal={setViewModal}
        />
      }
    </>
  );
};