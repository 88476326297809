import React, {useEffect} from "react";

export const Modal = (props) => {

  const {
    item,
    title,
    message,
    setViewModal,
    confirmation,
    setConfirmation,
    callback
  } = props;

  const formattedText = (message) => {
    let messageContainer = document.getElementById("messageContainer");
    let customMessage = message.split("\n").join("<br />");
    messageContainer.innerHTML = customMessage;
  };

  useEffect(() => {
    formattedText(message);
  }, [message]);

  useEffect(() => {
    initModalNavigationTab();
  }, []);

  const initModalNavigationTab = () => {
    const modal = document.getElementById('modal'); // select the modal by it's id
    const focusableElements = 'button, [href], input, [tabindex]:not([tabindex="-1"])';
    const firstFocusableElement = modal.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal
    const focusableContent = modal.querySelectorAll(focusableElements);
    const lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal

    modal.addEventListener('keydown', function (e) {
      // let isTabPressed = e.key === 'Tab' || e.key === 'Escape';
      let isTabPressed = e.key === 'Tab';

      if (!isTabPressed) {
        return;
      }

      if (e.shiftKey) { // if shift key pressed for shift + tab combination
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus(); // add focus for the last focusable element
          e.preventDefault();
          e.stopPropagation();
        }
      } else { // if tab key is pressed
        if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
          firstFocusableElement.focus(); // add focus for the first focusable element
          e.preventDefault();
          e.stopPropagation();
        }
      }

      // if (e.key === "Escape") {
      //   setViewFiltersMobile(false);
      //   document.getElementById('filtersMonitoringButton').focus();
      // }
    });
  };

  return (
    <div id="modal" className="cms-modal" role="dialog" aria-modal="true">
      <div className="cms-modal-container">
        <div className="cms-modal-header">
          <h4>{title}</h4>
          <button
            autoFocus
            id="closeModal"
            type="button"
            onClick={() => {
              setViewModal(false);
              window?.lastElementClicked?.focus();
            }}
          >
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"><path fill="#242424" d="M.4.55.47.47A.75.75 0 0 1 1.45.4l.08.07L8 6.94 14.47.47a.75.75 0 1 1 1.06 1.06L9.06 8l6.47 6.47c.27.27.3.68.07.98l-.07.08a.75.75 0 0 1-.98.07l-.08-.07L8 9.06l-6.47 6.47a.75.75 0 0 1-1.06-1.06L6.94 8 .47 1.53A.75.75 0 0 1 .4.55L.47.47.4.55Z"></path></svg>
            </span>
          </button>
        </div>
        <div className="cms-modal-body">
          <div id="messageContainer"></div>
        </div>
        {confirmation &&
          <div className="cms-modal-footer">
            <button
              type="button"
              onClick={() => {
                setConfirmation(false);
                callback(item);
                document.getElementById('closeModal').focus();
              }}
            >Si</button>
            <button
              type="button"
              onClick={() => {
                setViewModal(false);
                window?.lastElementClicked?.focus();
              }}
            >No</button>
          </div>
        }
      </div>
    </div>
  );
};