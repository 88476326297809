import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { serverUrl } from "./../../utils/config";

export const Footer = ({ resetOpenMenu, setCategory, categories, setIsLoading }) => {

  const [emailNewsletter, setEmailNewsletter] = useState("");
  const registerNewsletter = () => {
    if (emailNewsletter !== "") {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailNewsletter)) {

        document.getElementById('newsletter').value = "";
        axios.post(`${serverUrl()}/newsletter.php`, {
          email: emailNewsletter,
        })
          .then((response) => {
            switch (response.data.status) {
              case "success":
                alert('Registratazione alla newsletter avvenuta con successo');
                break;
              case "warning":
                alert('Sei già registrato');
                break;
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        alert("Indirizzo email non valido!");
      }
    }
  };

  return (
    <div className="footer">
      <div className="logoFooter">
        <div className="container">
          <img src={require(`./../../assets/logo-footer.png`)} alt="" />
        </div>
      </div>
      <div className="infoFooter">
        <div className="container">
          <div className="linksFooter">
            <ul>
              {categories.map((item, i) => {
                let categoryTag = item?.categoria.replace(/ /g, "-").replace("à", "a").toLowerCase();
                return (
                  <li className="submenuItem" key={i}>
                    <NavLink
                      onClick={() => {
                        setIsLoading(true);
                        setTimeout(() => {
                          resetOpenMenu();
                          setCategory(categoryTag);
                          document.querySelector('html').scrollTo(0, 0);
                        }, 500);
                      }}
                      className={({ isActive }) => isActive ? 'active' : ''}
                      to={`/categoria/${categoryTag}`}
                    >
                      {item?.categoria}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="newsletter">
            <div className="label">
              Inserisci la tua email per notizie sui prodotti, recensioni e guide all'acquisto
            </div>
            <div>
              <input
                type="text"
                id="newsletter"
                placeholder="Il tuo indirizzo email"
                onInput={(e) => {
                  setEmailNewsletter(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.currentTarget.value === "" && e.key === " ") {
                    e.preventDefault();
                  }
                }}
              />
            </div>
            <div>
              <button
                type="button"
                onClick={() => {
                  registerNewsletter();
                }}
              >
                Iscriviti!
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="linksPolicy">
        <div className="container">
          <ul>
            <li>
              <NavLink
                onClick={() => {
                  resetOpenMenu();
                  setIsLoading(true);
                  document.querySelector('html').scrollTo(0, 0);
                }}
                className={({ isActive }) => isActive ? 'active' : ''}
                to={'/privacy-policy'}
              >
                Privacy
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={() => {
                  resetOpenMenu();
                  setIsLoading(true);
                  document.querySelector('html').scrollTo(0, 0);
                }}
                className={({ isActive }) => isActive ? 'active' : ''}
                to={'/termini-condizioni'}
              >
                Termini e condizioni
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="copy">
        <div className="container">
          © {new Date().getFullYear()}. All rights reserved by Adviso.
        </div>
      </div>
    </div>
  );
};