import React from "react";
import { Hero } from "./hero/Hero";
import { Products } from "./products/Products";
import {Spinner} from "./../../spinner/Spinner";
import { Seo } from "../../seo/Seo";

export const Home = ({products, isLoading, setIsLoading}) => {
  
  return (
    <>
      <Seo 
        title={`Adviso`}
        description={`Prodotti consigliati da acquistare su Amazon in questo momento`}
        imageUrl={`${require('./../../../assets/logo-small.png')}`}
      />
      <div className="home">
        <Spinner isLoading={isLoading} setIsLoading={setIsLoading} />
        <Hero />
        <Products products={products} />
      </div>
    </>
  );
};