import React, { useState, useRef, useEffect } from "react";

export const Dropdown = (props) => {

  const {options, setFilterCategory, headerTitle, setHeaderTitle} = props;
  const wrapperRef = useRef(null);
  
  const [isListOpen, setIslistOpen] = useState(false);
  const toggleList = () => {
    setIslistOpen(!isListOpen);
  };

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      // Alert if clicked on outside of element
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIslistOpen(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  useOutsideAlerter(wrapperRef);

  return (
    <div className="cms-dropdown">
      <button
        id="dropdown"
        type="button"
        className="cms-dropdown-header"
        onClick={toggleList}
      >
        {headerTitle === "Seleziona" ? (
            <span className="cms-dropdown-header-title-placeholder">{headerTitle}</span>
          ) : (
            <span className="cms-dropdown-header-title">{headerTitle}</span>
          )
        }
        {isListOpen ? ( 
            <i className="icon-up-open"></i>
          ) : (
            <i className="icon-down-open"></i>
          )
        }
      </button>
      {isListOpen &&
        <div 
          className="cms-dropdown-list" 
          ref={wrapperRef} 
          style={{width: document.querySelector('.cms-dropdown').clientWidth}}
        >
          {options.filter((item) => item.enabled === "1").map((item, i) => {
            return (
              <button
                key={i}
                type="button"
                id={`dropdown-list-item-${i}`}
                className={`cms-dropdown-list-item ${headerTitle === item.categoria ? "active" : ""}`}
                onClick={() => {
                  setFilterCategory(item.categoria);
                  setHeaderTitle(item.categoria);
                  setIslistOpen(false);
                  document.getElementById('dropdown').focus();
                }}
              >
                {item.categoria}
              </button>
            );
          })}
        </div>
      }
    </div>
  );
};