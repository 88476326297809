import React from "react";

export const Hero = () => {
  return (
    <div className="hero">
      <div className="container">
        <div className="title">
          <h1>Prodotti consigliati da acquistare su Amazon</h1>
        </div>
        {/* <div className="image">
          <img width="500" height="281" className="amazon-image" src={require(`./../../../../assets/amazon-banner.jpg`)} alt="Amazon banner" />
        </div> */}
        <div className="paragraph">
          <p>
            Amazon è il luogo in cui gli acquirenti online si rivolgono per qualsiasi cosa, dagli elementi essenziali per la casa alle stravaganti novità stagionali.
            A volte, gli acquirenti sono alla ricerca di nuove fantastiche fotocamere e gadget high-tech, ma altre volte stanno cercando il miglior waffle maker che possono acquistare con € 20.
            <br /><br />
            Curiosi di vedere cosa stanno acquistando gli acquirenti su Amazon?
            <br /><br />
            Ecco i prodotti di punta che riteniamo valga la pena "aggiungere al vostro carrello".    
          </p>
        </div>
      </div>
    </div>
  );
};