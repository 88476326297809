import React, { useRef } from "react";
import { Menu } from "../menu/Menu";
import "./Header.module.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const Header = ({
  openSubMenu, 
  setOpenSubMenu, 
  openMenuMobile,
  setOpenMenuMobile,
  resetOpenMenu, 
  windowWidth,
  setCategory,
  categories,
  setIsLoading,
  setSearch,
  setProducts,
  products,
  setProductsFilteredSearch,
  setProductsFilteredCategory
}) => {

  const navigate = useNavigate();
  const inputSearch = useRef("");
  const ref = useRef(null);

  const getParameterByName = (name, url = window.location.href) => {
    name = name.replace(/[[\]]/g, "\\$&");
    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  };

  const searchProducts = () => {
    if(inputSearch.current !== ""){
      setIsLoading(true);
      setTimeout(() => {
        document.getElementById('searchInput').value = "";
        setSearch(inputSearch.current);
        const cloneProducts = [...products];
        let productsFiltered = cloneProducts.filter((item) => item.titolo.toLowerCase().indexOf(inputSearch.current.toLowerCase()) > -1 || item?.descrizione.toLowerCase().indexOf(inputSearch.current.toLowerCase()) > -1);
        setProductsFilteredSearch(productsFiltered);
        navigate(`/cerca?prodotto=${inputSearch.current}`);
      }, 300);
    }
  };

  useEffect(() => {
    if(products.length > 0){
      if (getParameterByName("prodotto")) {
        inputSearch.current = getParameterByName("prodotto");
        document.getElementById('searchInput').value = getParameterByName("prodotto");
        searchProducts();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  return (
    <>
      <div className="preHeader">
        <div className="container">
          <div className="items">
            <div></div>
            <div className="share">
              <div className="labelShare">
                {/* <span className="icon-share-squared"></span> */}
                <span>Condividi</span>
              </div>
              <div className="buttonShare">
                <button
                  type="button"
                  onClick={() => {
                    resetOpenMenu();
                    window.open("https://facebook.com/sharer/sharer.php?u=https://www.adviso.it/", "_blank");
                  }}
                >
                  <span className="icon-facebook-squared"></span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    resetOpenMenu();
                    const shareText = 'Adviso';
                    const shareUrl = 'https://www.adviso.it/';
                    const whatsappUrl = `whatsapp://send?text=${encodeURIComponent(shareText)}%20${encodeURIComponent(shareUrl)}`;
                    window.open(whatsappUrl, "_blank");
                  }}
                >
                  <span className="icon-whatsapp"></span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    resetOpenMenu();
                    window.open("mailto:?subject=Adviso&body=https://www.adviso.it/", "_self");
                  }}
                >
                  <span className="icon-mail-3"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header">
        <div className="container">
          <div className="items">
            <div className="left">
              <div className="logo">
                <img src={require(`./../../assets/logo.png`)} alt="" />
              </div>
              <div className="menu">
                <ul>
                  <li>
                    <NavLink 
                      onClick={() => {
                        setIsLoading(true);
                        resetOpenMenu();
                        setCategory("");
                      }} 
                      className={({ isActive }) => isActive ? 'active' : ''}  
                      to={'/' || '/home'}
                    >
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <NavLink 
                      onClick={() => {
                        setOpenMenuMobile(!openMenuMobile);
                        setOpenSubMenu(!openSubMenu);
                        if(!openSubMenu){
                          document.querySelector('body').classList.add('open-menu');
                        }else{
                          document.querySelector('body').classList.remove('open-menu');
                        }
                      }} 
                      className={({ isActive }) => isActive ? 'active' : ''}  
                    >
                      Categorie
                      <i className={`${openSubMenu ? `icon-up-open` : `icon-down-open`}`} />
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink 
                      onClick={() => {
                        setIsLoading(true);
                        resetOpenMenu();
                        setCategory("");
                      }} 
                      className={({ isActive }) => isActive ? 'active' : ''} 
                      to={'/contatti'} 
                    >
                      Contatti
                    </NavLink>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="right">
              <div className="search">
                <input 
                  type="text" 
                  ref={ref}
                  id="searchInput"
                  autoComplete="off"
                  placeholder="Cerca prodotti"
                  onChange={(e) => {
                    inputSearch.current = e.target.value;
                  }}
                  onKeyDown={(e) => {
                    if(e.key === "Enter"){
                      searchProducts();
                    }
                  }}
                />
                <button
                  type="button"
                  onClick={() => {
                    resetOpenMenu();
                    searchProducts();
                  }}
                >
                  <span className="material-symbols-outlined search-icon">search</span>
                </button>
              </div>
              {/* <div className="actionUser">
                <button
                  type="button"
                  className="registrati"
                  onClick={() => {
                    resetOpenMenu();
                  }}
                >
                  Registrati
                </button>
                <button
                  type="button"
                  className="accedi"
                  onClick={() => {
                    resetOpenMenu();
                  }}
                >
                  Accedi
                </button>
              </div> */}
            </div>
            <div className="buttonMenuMobile">
              <button
                type="button"
                onClick={() => {
                  resetOpenMenu();
                  setOpenMenuMobile(!openMenuMobile);
                  setOpenSubMenu(!openSubMenu);
                  if(!openSubMenu){
                    document.querySelector('body').classList.add('open-menu');
                  }else{
                    document.querySelector('body').classList.remove('open-menu');
                  }
                }}
              >
                {openMenuMobile ?
                  <span className="material-symbols-outlined close-icon">close</span>
                  :
                  <span className="material-symbols-outlined">menu</span>
                }
              </button>
            </div>
          </div>
        </div>
      </div>
      <Menu 
        openSubMenu={openSubMenu} 
        windowWidth={windowWidth}
        resetOpenMenu={resetOpenMenu}
        setCategory={setCategory}
        categories={categories}
        setIsLoading={setIsLoading}
        products={products}
        setSearch={setSearch}
        setProductsFilteredCategory={setProductsFilteredCategory}
        setProductsFilteredSearch={setProductsFilteredSearch}
      />
    </>
  );
};