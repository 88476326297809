import React from "react";
import { Spinner } from "./../../spinner/Spinner";
import { Seo } from "../../seo/Seo";

export const Privacy = ({isLoading, setIsLoading}) => {
  
  return (
    <>
      <Seo 
        title={`Adviso | Privacy`}
        description={`Prodotti consigliati da acquistare su Amazon in questo momento`}
        imageUrl={`${require('./../../../assets/logo-small.png')}`}
      />
      <Spinner isLoading={isLoading} setIsLoading={setIsLoading} />
      <div className="privacy">
        <div className="container">
          <div className="container-html">
            <h2>
              Informativa sul trattamento dei dati personali
            </h2>
            <p>Informativa ai sensi
              del Regolamento UE 2016/679 (“GDPR”)
            </p>
            <h3>
              <p>1. Quali tipi di dati
                raccogliamo?</p>
            </h3>
            <p>
              Quando usi i nostri servizi, accetti che la nostra azienda raccolga alcuni tuoi dati personali.
              <br />
              Questa pagina ha lo scopo di dirti quali dati raccogliamo, perché e come li usiamo.
            </p>
            <p>Trattiamo due tipi di dati:</p>
            <ul type="disc">
              <li>dati forniti dall'utente</li>
              <li>dati che raccogliamo automaticamente</li>
            </ul>
            <p>1.1 Dati forniti dall'utente</p>
            <p>
              Quando ti registri, inserisci o rispondi ad un annuncio ti chiediamo di fornirci alcuni dati che servono per poter usufruire del nostro servizio.
            </p>
            <p>Ad esempio questi sono i dati che protremmo chiederti:
            </p>
            <ul type="disc">
              <li>indirizzo email e password</li>
              <li>i tuoi dati anagrafici</li>
              <li>il tuo indirizzo per la spedizione</li>
            </ul>
            <p>Al
              momento della registrazione dovrai fornirci le seguenti informazioni:
            </p>
            <ul type="disc">
              <li>nome</li>
              <li>cognome</li>
              <li>email</li>
              <li>cellulare</li>
              <li>password</li>
              <li>indirizzo civico</li>
              <li>consensi per la privacy</li>
            </ul>
            <p>Dati di terzi</p>
            <p>
              Se fornisci dati personali di terzi, come ad esempio quelli dei tuoi familiari o
              amici, devi essere sicuro che questi soggetti siano stati adeguatamente
              informati e abbiano acconsentito al relativo trattamento nelle modalità
              descritte dalla presente informativa.
            </p>
            <p>Dati di minori di anni 16</p>
            <p>
              Se hai meno di 16 anni non puoi fornirci alcun dato personale né puoi
              registrarti, ed in ogni caso non assumiamo responsabilità per eventuali
              dichiarazioni mendaci da te fornite.
              <br />
              Qualora ci accorgessimo dell'esistenza di
              dichiarazioni non veritiere procederemo con la cancellazione immediata di ogni
              dato personale acquisito.
            </p>
            <p>1.2 Dati che
              raccogliamo automaticamente
            </p>
            <p>Raccogliamo
              i seguenti dati mediante i servizi che utilizzi:
            </p>
            <ul type="disc">
              <li>
                dati tecnici: ad esempio tipo di browser, informazioni sul tuo computer, dati relativi
                alla posizione attuale (approssimativa) dello strumento che stai utilizzando;
              </li>
              <li>dati raccolti utilizzando i cookie o tecnologie similari</li>
            </ul>
            <h3>
              <p>2. Come utilizziamo i dati raccolti?</p>
            </h3>
            <p>
              Utilizziamo i dati raccolti per offrirti ogni giorno il nostro servizio, per informarti sulle nostre attività commerciali o per proporti un servizio
              più personalizzato ed in linea con i tuoi interessi.
            </p>
            <p>
              Utilizziamo i tuoi dati per garantirti l'accesso ai nostri servizi e la loro erogazione, tra cui:
            </p>
            <ul type="disc">
              <li>registrazione e creazione di un'area riservata.</li>
              <li>acquisti di prodotti</li>
            </ul>
            <p>
              Tali trattamenti sono necessari per erogare correttamente i
              servizi di Adviso nei confronti degli utenti che vi aderiscono.
            </p>
            <p>
              Tali trattamenti si basano sul legittimo
              interesse del Titolare al miglioramento del servizio ed alla sua
              implementazione e puoi opporti, nei casi previsti dalla legge, in ogni momento.
            </p>
            <p>2.2 Per informarti riguardo alle nostre attività commerciali
            </p>
            <p>
              Utilizziamo i dati raccolti, qualora tu ci abbia fornito espressamente il consenso, per
              informarti riguardo ad attività promozionali che potrebbero interessarti.
              <br />
              <br />
              In particolare, li utilizziamo per:
            </p>
            <ul type="disc">
              <li>
                comunicarti attività promozionali, commerciali e pubblicitarie sui nostri prodotti,
                tramite posta elettronica, invio SMS o notifiche push;
              </li>
              <li>
                fare attività di analisi e di reportistica connessa ai sistemi di
                comunicazione promozionale, come ad esempio il rilevamento del numero delle
                e-mail aperte, dei click effettuati sui link presenti all'interno della
                comunicazione, la tipologia del dispositivo utilizzato per leggere la
                comunicazione ed il relativo sistema operativo o l'elenco dei non iscritti alla
                newsletter.
              </li>
            </ul>
            <p>2.3 Per offrirti un servizio personalizzato</p>
            <p>
              Elaboriamo i dati raccolti, qualora tu ci abbia fornito espressamente il consenso, per
              analizzare le tue abitudini o scelte di consumo al fine di proporti un servizio
              sempre più personalizzato ed in linea con i tuoi interessi e di migliorare la
              nostra offerta commerciale. Tali analisi non sono in ogni caso correlate ad un
              processo decisionale automatizzato.
            </p>
            <h3>
              <p>3. Il conferimento dei dati è obbligatorio?</p>
            </h3>
            <p>
              Il conferimento dei dati personali è obbligatorio esclusivamente per i trattamenti necessari all'erogazione dei
              servizi offerti da Adviso (l'eventuale rifiuto per <a href="#">i termini d'uso del servizio</a>
              rende impossibile l'utilizzo del servizio stesso); è invece facoltativo per le finalità promozionali e
              di profilazione e l'eventuale rifiuto di prestare il consenso non ha conseguenze negative sull'erogazione del servizio offerto.
            </p>
            <h3>
              <p>4. Come puoi avere informazioni sui dati, modificarli, cancellarli
                o averne una copia?</p>
            </h3>
            <p>
              <u></u>
            </p>
            <u></u>
            <p>4.1 Esportazione e cancellazione dei dati
              personali
            </p>
            <p>
              Per esportare i tuoi dati personali (takeout) o chiederne la cancellazione puoi inviare una richiesta all'indirizzo
              e-mail <a href="mailto:info@adviso.it">info@adviso.it</a>
              dalla casella di posta elettronica con cui ti sei registrato. La cancellazione verrà effettuata nel più breve tempo possibile.
            </p>
            <h3>
              <p>5. Come assicuriamo la protezione dei tuoi dati?</p>
            </h3>
            <p>
              <u></u>
            </p>
            <u></u>
            <p>
              I dati sono raccolti, secondo le indicazioni della normativa di riferimento, con particolare riguardo alle misure di sicurezza previste dal
              GDPR (art. 32) per il loro trattamento mediante strumenti informatici, manuali ed automatizzati e comunque in modo da garantire la sicurezza e la riservatezza
              dei dati stessi.
            </p>
            <p>
              Nel rispetto della normativa applicabile, è attivo un sistema di verifica antispam sulle comunicazioni tra utenti.
              <br />
              I dati ivi inseriti potranno essere verificati al solo scopo di individuare attività illecite o contenuti non conformi alle Condizioni generali del Servizio, ma non
              saranno trattati o comunicati per finalità commerciali o promozionali.
            </p>
            <h3>
              <p>6. Ulteriori informazioni</p>
            </h3>
            <p>6.1 Sondaggi</p>
            <p>
              Potremmo chiederti, durante la navigazione, la disponibilità a partecipare a sondaggi mirati allo
              scopo di conoscere il tuo parere ed il grado di soddisfazione relativamente ai servizi forniti.
            </p>
            <p>
              All'interno dei sondaggi potrà essere richiesto, in maniera facoltativa, di fornire un indirizzo e-mail al
              fine di ricevere notizie ed informazioni sui servizi di Adviso.
            </p>
            <p>
              Ti ricordiamo che la partecipazione ai sondaggi è del tutto facoltativa e che puoi sempre opporti a tale trattamento.
            </p>
            <h3>
              <p>7. L'informativa sulla privacy può subire modifiche nel tempo?</p>
            </h3>
            <p>
              La presente informativa potrebbe essere soggetta a modifiche.
              <br />
              Qualora vengano apportate sostanziali modifiche all'utilizzo dei dati relativi all'utente da
              parte del Titolare, quest'ultimo avviserà l'utente pubblicandole con la massima evidenza sulle proprie pagine o tramite mezzi alternativi o similari.
            </p>
            <h3><p>8. Cookie</p></h3>
            <p>1. Cosa sono i cookie?</p>
            <p>
              I cookie sono file di testo di dimensioni ridotte che i siti visitati dagli utenti inviano ai loro terminali o
              dispositivi, in cui vengono memorizzati per essere trasmessi nuovamente agli
              stessi siti in occasione di visite successive.
              <br />
              I cookie (e/o tecnologie similari come, ad esempio, le tecnologie SDK per il mondo mobile) possono
              essere memorizzati in modo permanente (cookie persistenti) sul tuo dispositivo
              o avere una durata variabile; possono infatti cancellarsi con la chiusura del
              browser o avere una durata limitata alla singola sessione (cookie di sessione).
              <br />
              I cookie possono essere installati da Adviso (cookie di prima parte) o da altri
              siti web (cookie di terze parti).
            </p>
            <p>
              I cookie sono utilizzati per diverse finalità come meglio specificato al punto 2 della presente pagina.
            </p>
            <p>
              Per le attività di profilazione, i dati personali raccolti tramite cookie sono trattati per un periodo massimo
              di 12 mesi dal momento in cui viene prestato il consenso al trattamento.
              <br />
              Di seguito troverai tutte le informazioni sui cookie installati
              attraverso il sito di Adviso e/o relative applicazioni e le indicazioni
              necessarie su come gestire le tue preferenze riguardo ad essi.
            </p>
            <p>
              Ti ricordiamo che la nostra piattaforma supporta i browser e/o applicazioni di seguito individuati ai fini di una
              corretta erogazione del servizio. Per risultati ottimali si consiglia di scaricare la versione più recente del proprio browser e/o app.
            </p>
            <p>
              Ti ricordiamo che Adviso non può garantire il corretto funzionamento del servizio e l'effettività delle
              indicazioni contenute nella presente informativa per precedenti versioni di browser e/o app non supportati.
            </p>
            <p>
              2. Cookie di prima parte e relative finalità
            </p>
            <p>
              Le finalità principali dei cookie installati da Adviso sono:
            </p>
            <ul type="disc">
              <li>
                tecniche, vengono cioè utilizzati per finalità connesse all'erogazione del servizio e per consentire o migliorare la
                navigazione su Adviso o memorizzare le ricerche. Tali cookie sono indispensabili per garantire alla nostra piattaforma un corretto funzionamento.
              </li>
              <li>
                analitiche, per raccogliere informazioni statistiche sull'utilizzo del servizio da parte degli utenti (e.g. numero
                visitatori, pagine visitate, …). Utilizziamo questi cookie per analizzare il traffico sulle nostre pagine in modo anonimo, senza memorizzare dati personali.
              </li>
              <li>
                analizzare le abitudini, scelte di consumo e posizione geografica al fine di promuovere pubblicità personalizzata
                anche di terze parti (a cui possono essere comunicate informazioni che non identificano direttamente l'utente)
                e per effettuare analisi volte all'invio di comunicazioni personalizzate da parte di Adviso.
              </li>
              <li>
                È possibile visualizzare la lista di tutti i cookie utilizzati da Adviso nella sezione cookie delle impostazioni
              </li>
            </ul>
            <p>
              3. Come viene prestato il consenso (opt-in) all'utilizzo dei cookie?
            </p>
            <p>
              Il consenso all'utilizzo dei cookie di profilazione viene prestato dall'utente attraverso le seguenti modalità: accettando il banner
              contenente l'informativa breve e può essere revocato in ogni momento. Tutti i cookie tecnici non richiedono consenso, pertanto vengono installati
              automaticamente a seguito dell'accesso al sito o al servizio.
            </p>
            <p>
              4. Come revocare (opt-out) il consenso all'utilizzo dei cookie?
            </p>
            <p>
              I cookie possono essere completamente disattivati dal browser utilizzando l'apposita funzione prevista nella maggior parte dei programmi di navigazione.
              <br />
              È bene sapere però che disattivando i cookie alcune delle funzionalità di Adviso potrebbero non essere utilizzabili.
              <br />
              <br />
              Riportiamo di seguito i link alle informative dei principali browser per ulteriori informazioni sulla disattivazione dei cookie:
            </p>
            <ul type="disc">
              <li><a target="_blank" href="https://support.google.com/chrome/answer/95647?hl=it">Chrome</a></li>
              <li><a target="_blank" href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie">Firefox</a></li>
              <li><a target="_blank" href="http://windows.microsoft.com/it-it/internet-explorer/delete-manage-cookies#ie=ie-11">Internet Explorer</a></li>
              <li><a target="_blank" href="https://support.apple.com/it-it/HT201265">Safari</a></li>
              <li><a target="_blank" href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy">Edge</a></li>
            </ul>
            <p>
              Con riferimento ai cookie di profilazione finalizzati ad offrirti pubblicità personalizzata, ti informiamo che, qualora esercitassi l'opt-out, continuerai in ogni caso a ricevere pubblicità di tipo generico.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};  