import React, { useState, useRef } from "react";
import axios from "axios";
import { serverUrl } from "./../../utils/config";

export const Login = (props) => {

  const {setLogin} = props;  
  
  const [showPassword, setShowPassword] = useState(false);

  const emailRef = useRef(null);
  const [emailEmpty, setEmailEmpty] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const reg_email = /^([a-zA-Z0-9]+[a-zA-Z0-9._%\-+]*@(?:[a-zA-Z0-9-]{2,}\.)+[a-zA-Z]{2,})$/;
  const [emailNotFound, setEmailNotFound] = useState(false);
  
  const passwordRef = useRef(null);
  const [passwordEmpty, setPasswordEmpty] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const reg_password = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[\W]).{8,}$/;
  const [passwordNotFound, setPasswordNotFound] = useState(false);

  const emailHandle = (e) => {
    if (e.currentTarget.value === "" && e.key === " ") {
      e.preventDefault();
    } else {
      setEmailEmpty(false);
      setEmailError(false);
      setEmailNotFound(false);
    }
  };

  const passwordHandle = (e) => {
    if (e.currentTarget.value === "" && e.key === " ") {
      e.preventDefault();
    } else {
      setPasswordEmpty(false);
      setPasswordError(false);
      setPasswordNotFound(false);
    }
  };

  const sendForm = (e) => {
    e.preventDefault();
    let formIsValid = true;
    if (emailRef.current.value === "") { setEmailEmpty(true); formIsValid = false; } else { setEmailEmpty(false); }
    if (passwordRef.current.value === "") { setPasswordEmpty(true); formIsValid = false; } else { setPasswordEmpty(false); }


    if (emailEmpty && passwordEmpty) {
      formIsValid = false;
    }

    if (!reg_email.test(emailRef.current.value)) {
      setEmailError(true);
      formIsValid = false;
    } else {
      setEmailError(false);
    }

    if (!reg_password.test(passwordRef.current.value)) {
      setPasswordError(true);
      formIsValid = false;
    } else {
      setPasswordError(false);
    }

    if (formIsValid) {
      axios
      .post(`${serverUrl()}/cms/accesso.php`, {
        email: emailRef.current.value,
        password: passwordRef.current.value,
      })
      .then((response) => {
        // console.log(response);
        if (response.data.status === "success") {
          setEmailNotFound(false);
          setPasswordNotFound(false);
          setLogin(false);
          sessionStorage.setItem("userLogin", true);
          sessionStorage.setItem(
            "userData",
            JSON.stringify(response.data.data)
          );
        } else {
          console.log(response.data.message);
          setEmailNotFound(false);
          setPasswordNotFound(false);
          let errorsMessages = response.data.data;
          errorsMessages.forEach((error, i) => {
            if(error.message === 'email'){
              setEmailNotFound(true);
            }
            if(error.message === 'password'){
              setPasswordNotFound(true);
            }
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
    }

  };

  return (
    <div className="cms-login">
      <div className="cms-logo-login">
        <img src={require(`./../../../../assets/logo-footer.png`)} alt="" />
      </div>
      <div className="cms-login-container">
        <div className="cms-login-field">
          <label htmlFor="email">Email</label>
          <input 
            ref={emailRef}
            type="email"
            id="email"
            name="email"
            className={(emailEmpty || emailError || emailNotFound) ? "cms-error" : ""}
            onKeyDown={(e) => emailHandle(e)}
          />
          <div className="cms-errorStatus">
            {(emailEmpty || emailError) &&
              <span className="cms-emptyField">
                {emailEmpty ? "Campo obbligatorio" : "Email non valida"}
              </span>
            }
            {(emailNotFound) &&
              <span className="cms-emptyField">
                Email non corretta
              </span>
            }
          </div>
        </div>
        <div className="cms-login-field">
          <label htmlFor="password">Password</label>
          <input  
            ref={passwordRef}
            type={showPassword ? "text" : "password"} 
            id="password"
            name="password"
            className={(passwordEmpty || passwordError || passwordNotFound) ? "cms-error" : ""}
            onKeyDown={(e) => passwordHandle(e)}
          />
          <div className="cms-errorStatus">
            {(passwordEmpty || passwordError) &&
              <span className="cms-emptyField">
                {passwordEmpty ? "Campo obbligatorio" : "Password non valida"}
              </span>
            }
            {(passwordNotFound) &&
              <span className="cms-emptyField">
                Password non corretta
              </span>
            }
          </div>
          <button
            type="button"
            className="cms-login-show-password"
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          >
            {showPassword ? (
              <span className="cms-hide-password">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><path fill="#212121" d="M1.4246.5756a.6.6 0 1 0-.8485.8486l4.1987 4.1986C2.7649 6.99 1.2685 9.1285.7366 11.6773a.6.6 0 0 0 1.1747.2452C2.3959 9.6003 3.7888 7.6754 5.641 6.489l1.9034 1.9034c-1.0565.7628-1.744 2.0049-1.744 3.4075 0 2.3196 1.8804 4.2 4.2 4.2 1.4026 0 2.6447-.6876 3.4075-1.744l5.1682 5.1683a.6001.6001 0 0 0 .8486-.8486l-18-18Zm11.1191 12.8161c-.5305.8459-1.4712 1.4082-2.5433 1.4082-1.6569 0-3-1.3431-3-3 0-1.0721.5623-2.0128 1.4082-2.5433l4.1351 4.1351Zm-2.3951-5.7892 4.0492 4.0492c-.0763-2.2018-1.8474-3.9729-4.0492-4.0492Zm-.1482-2.4026a7.9583 7.9583 0 0 0-1.999.2554l-.9637-.9637A9.178 9.178 0 0 1 10.0004 4c4.4321 0 8.331 3.2077 9.2638 7.6774a.6.6 0 0 1-1.1747.2452c-.8202-3.9303-4.2423-6.7226-8.0891-6.7226Z"/></svg>
              </span>
            ) : (
              <span className="cms-show-password">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="12" fill="none"><path fill="#2A2A2A" d="M1.9101 7.9226C2.7303 3.9923 6.1525 1.2 9.9992 1.2c3.8468 0 7.2689 2.7923 8.0892 6.7226a.6.6 0 1 0 1.1746-.2452C18.3303 3.2077 14.4313 0 9.9992 0 5.5672 0 1.6682 3.2077.7354 7.6774a.6.6 0 1 0 1.1747.2452ZM9.9992 3.6c-2.3196 0-4.2 1.8804-4.2 4.2 0 2.3196 1.8804 4.2 4.2 4.2 2.3196 0 4.2-1.8804 4.2-4.2 0-2.3196-1.8804-4.2-4.2-4.2Zm-3 4.2c0-1.6568 1.3432-3 3-3 1.6569 0 3 1.3432 3 3s-1.3431 3-3 3c-1.6568 0-3-1.3432-3-3Z"/></svg>
              </span>
            )}            
          </button>
        </div>
        <div className="cms-login-field">
          <button
            type="button"
            className="cms-login-button"
            onClick={(e) => {sendForm(e);}}
          >
            Accedi
          </button>
        </div>
      </div>
    </div>
  );
};