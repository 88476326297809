import React, {useState, useEffect} from "react";

export const Spinner = ({isLoading, setIsLoading}) => {
  
  useEffect(() => {
    if(isLoading){
      setTimeout(() => {
        document.querySelector('html').scrollTo(0, 0);
        setIsLoading(false);
      }, 1000);
    }
  }, [isLoading]);
  return (
    <div className={`spinner ${isLoading ? "active" : ""}`}>
      <div className="spinner-container">
        {/* <div className="image">
          <img src={require(`./../../assets/logo.png`)} alt="logo spinner" />
        </div> */}
        <div className="lds-dual-ring"></div>
      </div>
    </div>
  );
};