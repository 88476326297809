import React from "react";

export const NotFound = () => {
  return (
    <div className="notFound">
      <div className="container">
        <div className="titleNotFound">
          <h1>Pagina non trovata</h1>
        </div>
        <p>La pagina che stai cercando probabilmente è stata rimossa o ha cambiato nome.</p>
      </div>
    </div>
  );
};