import React, {useState} from 'react';
import { Route, Routes, BrowserRouter, Navigate} from "react-router-dom";
import "./fontello/css/fontello.css"
import './styles/styles.scss';
import { Pages } from "./components/pages/Pages";
import { NotFound } from './components/pages/notFound/NotFound';
import { Cms } from "./components/cms/Cms";
import { Login } from "./components/cms/components/login/Login";


const App = () => {

  const userLogin = sessionStorage.getItem("userLogin");
  const [login, setLogin] = useState(userLogin ? false : true);

  return (
    <>
      <BrowserRouter basename={'/'}>
        <Routes>
          <Route
            index
            element={
              <Pages page={`home`} />
            }
          />
          <Route
            path="/categoria/:categoria"
            element={
              <Pages page={`categorie`} />
            }
          />
          <Route
            path="/contatti"
            element={
              <Pages page={`contatti`} />
            }
          />
          <Route
            path="/cerca"
            element={
              <Pages page={`cerca`} />
            }
          />
          <Route
            path="/termini-condizioni"
            element={
              <Pages page={`termini-condizioni`} />
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <Pages page={`privacy-policy`} />
            }
          />
          <Route
            path="/cms"
            element={
              !login ? (<Cms />) : (<Login setLogin={setLogin} />)
            }
          />
          <Route path="*" element={<Navigate replace to="/not-found" />} />
          <Route path="/not-found" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
