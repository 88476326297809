import React from "react";

export const Products = ({products}) => {

  return (
    <>
      <div className="products">
        <div className="container">
          {products.length > 0 ? products.map((item, i) => {
            return (
              <div key={i} className="product">
                <div className="image">
                  <img width="400" height="400" src={require(`./../../../../assets/img/${item?.img}`)} alt="" />
                </div>
                <div className="info">
                  <div className="counter">{i + 1}</div>
                  <div className="title">{item?.titolo}</div>
                  <div className="linkContainer">
                    <a className="amazonLink" target="_blank" rel="noreferrer" href={item?.link}>
                      <i className="icon-amazon"></i>
                      <span>Acquista su Amazon</span>
                    </a>
                  </div>
                  <div className="description">{item?.descrizione}</div>
                </div>
              </div>
            );
          }):(
            <div className="emptyState">
              Non ci sono prodotti
            </div>
          )}
        </div>
      </div>
    </>
  );
};