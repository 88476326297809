import axios from "axios";
import { serverUrl } from "./../../utils/config";

export const Logout = () => {
  const userData = JSON.parse(sessionStorage.getItem("userLogin"));
  const userID = userData?.user;
  axios.post(`${serverUrl()}/cms/logout.php`, {
    user: userID
  })
  .then((response) => {
    // console.log(response);
    if (response.data.status === "success") {
      // Remove saved data from sessionStorage
      sessionStorage.removeItem("userData");
      sessionStorage.removeItem("userLogin");
      window.location.href = '/cms';
    }
  })
  .catch((error) => {
     console.error(error);
  });
};