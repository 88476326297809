import React from "react";
import { Logout } from "../logout/Logout";

export const Header = () => {
  return (
    <div className="cms-header">
      <div className="cms-header-logo">
        <img src={require(`./../../../../assets/logo-footer.png`)} alt="" />
      </div>
      <div className="cms-header-menu">
        <a 
          href={`${window.location.hostname !== "localhost" ? "https://adviso.altervista.org/" : "http://localhost:3000"}`}
          target="_blank"
          rel="noreferrer"
          title="Vai al sito"
        >
          <span>
            <i className="icon-link-ext"></i>
          </span>
          Vai al sito
        </a>
        <button
          type="button"
          onClick={() => {
            Logout()
          }}
        >
          <i className="icon-logout-1"></i>
          Logout
        </button>
      </div>
    </div>
  );
};