import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Products } from "./../home/products/Products";
import { Spinner } from "./../../spinner/Spinner";
import { Seo } from "../../seo/Seo";

export const Categorie = ({
  products,
  category,
  categories,
  setCategory,
  isLoading,
  setIsLoading,
  productsFilteredCategory,
  setProductsFilteredCategory
}) => {

  const { categoria } = useParams();
  const capitalizeFirstletter = (s) => {
    if (s && s.length > 0) return s.charAt(0).toUpperCase() + s.slice(1);
    return s;
  };

  useEffect(() => {
    setCategory(categoria);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (categoria !== undefined && categoria !== "") {
      if(products.length > 0){
        setProductsFilteredCategory(
          products.filter((item) => {
            return item.categoria.toLowerCase().indexOf(categoria.toLowerCase()) > -1;
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  return (
    <>
      <Seo
        title={`Adviso | ${capitalizeFirstletter(categoria?.replace(/-/g, " ").replace("maternita", "maternità"))}`}
        description={`Prodotti per ${capitalizeFirstletter(categoria?.replace(/-/g, " ").replace("maternita", "maternità"))} consigliati da acquistare su Amazon in questo momento`}
        imageUrl={`${require('./../../../assets/logo-small.png')}`}
      />
      <Spinner isLoading={isLoading} setIsLoading={setIsLoading} />
      {productsFilteredCategory.length > 0 ? (
        <div className="categorie">
          {categoria !== undefined && categoria !== "" && (
            <>
              <div className="titleCategory">
                <div className="container">
                  <h1>{capitalizeFirstletter(categoria?.replace(/-/g, " ").replace("maternita", "maternità"))}</h1>
                </div>
              </div>
              <Products products={productsFilteredCategory} />
            </>
          )}
        </div>
       ) : (
        <div className="categorie">
          <div className="titleCategoryError">
            <div className="container">
              <h1>Categoria <b>{categoria}</b> non trovata</h1>
            </div>
          </div>
          <div className="container">
            <p>Seleziona un altra categoria dal menu.</p>
          </div>
        </div>
      )}
    </>
  );
};