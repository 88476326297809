import React from "react";

export const Sidebar = (props) => {

  const { page, setPage, setIsLoading } = props;

  return (
    <div className="cms-sidebar">
      <ul>
        <li>
          <button 
            type="button"
            className={`cms-button-menu ${page === "categories" ? 'active' : ''}`}
            onClick={() => { setPage('categories'); setIsLoading(true); }}
          >
            Categorie
          </button>
        </li>
        <li>
          <button 
            type="button"
            className={`cms-button-menu ${page === "products" ? 'active' : ''}`}
            onClick={() => { setPage('products'); setIsLoading(true); }}
          >
            Prodotti
          </button>
        </li>
        <li>
          <button 
            type="button"
            className={`cms-button-menu ${page === "add-product" ? 'active' : ''}`}
            onClick={() => { setPage('add-product'); setIsLoading(true); }}
          >
            Inserisci prodotto
          </button>
        </li>
      </ul>
    </div>
  );
};