import React, {useState, useEffect} from "react";
import {Spinner} from "../spinner/Spinner";
import { Header } from "./components/header/Header";
import { Body } from "./components/body/Body";
import './styles/styles.scss';

export const Cms = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState('categories');

  useEffect(() => {
    document.querySelector('body').style.overflow = 'hidden';
  }, []);

  return (
    <div className="cms">
      <Spinner isLoading={isLoading} setIsLoading={setIsLoading} />
      <Header />
      <Body page={page} setPage={setPage} isLoading={isLoading} setIsLoading={setIsLoading} />
    </div>
  );
};