import React, {useEffect} from "react";

export const Spinner = ({isLoading, setIsLoading}) => {
  
  useEffect(() => {
    if(isLoading){
      setTimeout(() => {
        document.querySelector('html').scrollTo(0, 0);
        setIsLoading(false);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);
  return (
    <div className={`spinner ${isLoading ? "active" : ""}`}>
      <div className="spinner-container">
        <div className="lds-dual-ring"></div>
      </div>
    </div>
  );
};